.card-header {
    background-color: #EEEEEE !important;
    border-radius: 10px !important;
}
.card-shadows {
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.442);
}

.card-itm-ot {
    background-color: #e9e9e9;
    border-radius: 10px !important;
}

.card-body {
    /* padding: 12px 10px; */
}

.ot-item {
    background-color: #fff;
    border-radius: 10px !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.346);
}
.center-avatar {
    padding: auto;
    margin: auto;
}
.cont-avatar {
    width: 70%;
    margin: auto;
    display: block;
}
.separador {
    background-color: #A49D9D;
}

@media (max-width: 767px) {
    .container-crudmenu {
        background-color: #fff;
        width: 100vw;
    }
    .container-crudmenu_2 {
        background-color: #fff;
        width: 100vw;
    }
    .spacin-crud {
        margin-bottom: 60px;
    }
    .fixed-drud {
        right: 0px;
        width: 99vw;
        position: fixed;
        z-index: 1000000;
    }
    .fixed-footer {
        background-color: #fff;
        bottom: 0px;
        width: 100vw;
        position: fixed;
        z-index: 1000000;
    }
}

@media (min-width: 768px){
    .container-crudmenu {
        width: 500px;
    }
    .container-crudmenu_2 {
        width: 516px;
        max-width: 516px;
    }
    .spacin-crud {
        margin-bottom: 60px;
        background-color: #fff;
    }
    .fixed-drud {
        right: 30px;
        width: 488px;
        position: fixed;
        z-index: 1000000;
    }
    .fixed-footer {
        background-color: #fff;
        border-top: 1px solid rgb(180, 180, 180);
        bottom: 0px;
        width: 502px;
        position: fixed;
        z-index: 1000000;
    }
}

.header-crud {
    background-color: #fff;
    border-bottom: 2px solid gray;
}
.shdowBan {
    box-shadow: 0px 5px 10px rgba(11, 11, 11, 0.4);
}
.card-ot-crud {
    border: 1px solid rgb(183, 183, 183);
    border-radius: 8px;
}
.card-ot-crud-2 {
    border-radius: 8px;
}
.text-01 {
    font-size: medium;
}
.text-02 {
    font-size: small !important;
}
.center-v {
    text-align: center;
    align-self: center;
    padding: 0px;
    margin: 0px;
}
.puntero{
    cursor: pointer;
}
.btn-green {
    color: #00C853 !important;
    border: 1px solid #00C853 !important;
}
.btn-green-active {
    color: black !important;
    border: 1px solid #00C853 !important;
    background-color: #00C853 !important;
}
.btn-naranjo {
    color: #FFA000 !important;
    border: 1px solid #FFA000 !important;
}
.btn-naranjo-active {
    color: black !important;
    border: 1px solid #FFA000 !important;
    background-color: #FFA000 !important;
}
.btn-rojo {
    font-weight: bold;
    color: #EF5350 !important;
    border: 1px solid #EF5350 !important;
}
.btn-rojo-active {
    color: black !important;
    border: 1px solid #EF5350 !important;
    background-color: rgb(255, 73, 73) !important;
}
.btn-na {
    color: #BFBFBF !important;
    border: 1px solid #BFBFBF !important;
}
.btn-na-active {
    color: #000000 !important;
    border: 1px solid #BFBFBF !important;
    background-color: #BFBFBF !important;
}
.MuiPaper-root-- {
    width: 516px !important;
}
.text-02-rating {
    font-size: small !important;
    padding-bottom: 10px !important;
}
.comlpete-task-badge {
    margin-top: 10px;
    background-color: #e1e0e0;
    padding: 5px 10px 8px 5px;
    border-radius: 30px;
}

.pdf-borde {
    border: solid 1px black;
    border-radius: 5px;
}
.pdf-borde-c {
    border: solid 1px black;
}

/* filtro ot */
.filter-ot {
    position: absolute;
    right: 5px;
    padding-top: 4px;
}
.menu-filter{
    position: absolute;
    background-color: #f6f5f5;
    border-radius: 5px;
    top: 50px;
    right: 0px;
    border: 1px solid rgb(220, 219, 219);
    padding: 15px 5px;
    /* height: 150px; */
    z-index: 100;
    margin: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.346);
}
.menu-filter-none {display: none;}
.inputFecha {
    border: 1px solid #CACACA;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    font-family: monospace;
}

.img-show {
    border-radius: 5px;
    height: 200px;
}
.frame_1 {
    border: 1px solid black;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .frame_2 {
    border: 1px solid black;
    padding: 10px 10px 0px 10px;
    border-radius: 15px 15px 20px 20px;
    background-color: black;
  }
  
  .label_qr {
    font-family: 'VT323', monospace;
    color: #fff;
  }

  .swal2-container {
    z-index: 1000000 !important;
  }